<div class="login-container">
    <mat-card class="login-card">
      <mat-card-header>
        <mat-card-title>Welcome to our yap app</mat-card-title>
      </mat-card-header>
  
      <mat-divider></mat-divider>
  
      <mat-card-content>
        <div class="login-button">
          <button mat-raised-button color="primary" (click)="signInWithGoogle()" class="google-btn">
            <img
              src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png"
              alt="Google"
              class="google-icon"
            />
            <span class="btn-text">Sign in with Google</span>
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  