<div class="dashboard">

    <h1>Dashboard - user has logged in</h1>
  
    <p>
  
      {{ profile() | json }}
  
    </p>
  
    <button (click)="logOut()">LogOut</button>
  
  </div>