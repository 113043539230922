import { Injectable, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from './auth.config';

@Injectable({
  providedIn: 'root',
})
export class AuthGoogleService {
  // Inject the OAuthService for managing OAuth operations
  private oAuthService = inject(OAuthService);

  // Inject Angular's Router for potential route-based navigation
  private router = inject(Router);

  // Initialize a reactive signal to store the user's profile information
  profile = signal<any>(null);

  constructor() {
    // Call the configuration method when the service is initialized
    this.initConfiguration();
  }

  // Configure OAuth service settings and try to log in the user if there's an active session
  initConfiguration() {
    // Configure the OAuthService with the specified auth settings from authConfig
    this.oAuthService.configure(authConfig);

    // Enable silent token refresh, allowing the session to renew without interrupting the user
    this.oAuthService.setupAutomaticSilentRefresh();

    // Load the discovery document (OAuth server metadata) and try to log in if there's an existing session
    this.oAuthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      // If there's a valid ID token, update the profile signal with user information
      if (this.oAuthService.hasValidIdToken()) {
        this.profile.set(this.oAuthService.getIdentityClaims());
      }
    });
  }

  // Initiate the login flow, redirecting the user to the Google OAuth login page
  login() {
    this.oAuthService.initImplicitFlow();
  }

  // Log the user out by revoking tokens and clearing the local profile data
  logout() {
    // Revoke the access token and perform a logout from the authorization server
    this.oAuthService.revokeTokenAndLogout();

    // Log out locally by clearing any saved tokens and ending the session
    this.oAuthService.logOut();

    // Reset the profile data to null
    this.profile.set(null);
  }

  // Retrieve the current profile data stored in the reactive signal
  getProfile() {
    return this.profile();
  }

  getToken() {
    return this.oAuthService.getIdToken();
  }
  
}
